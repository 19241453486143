/* src/components/MainHeader.css */
.main-header {
  display: flex;
  width: 100%;
  position: absolute; /* Position the header above the map */
  z-index: 11;
  height: 40px;
}


.header-tab,
.contact-link {
  margin-top: 3px;
  gap: 10px;
  margin-left: 10px;
  height: 30px;
  background-color: #f1f1f1;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  text-decoration: none;
  color: black;
  width: 200px; /* Ensure it spans the full width of the header */
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  border-radius: 5px;
  border: solid black 2px;

}

.header-tab:hover {
  background-color: #006b45; /* Same as active color */
  color: white;
  border-color: #333; /* Optional: Darker border on hover */
}

.header-tab.active {
  background-color: #9d7715;
  color: white;
}

/* Align the login button to the far right */
.auth-section {
  margin-left: auto; /* Push the login button to the right */
  display: flex;
  align-items: center;
}/* Ensure the login/sign-in button is always on top */
.auth-button {
  z-index: 9999; /* Ensure it's above everything else */
  position: relative; /* Needed to anchor the dropdown to this button */
}

/* Dropdown menu styling */
.auth-dropdown {
  position: absolute; /* Position relative to the button */
  top: 40px; /* Adjust to appear below the button */
  right: 0; /* Align dropdown to the right */
  background-color: white; /* Background color for the dropdown */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  border-radius: 5px;
  z-index: 9999; /* Ensure it's above everything else */
  width: 200px; /* Adjust width */
  padding: 10px;
}

/* Dropdown menu items */
.auth-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
}

.auth-dropdown-item:hover {
  background-color: #f1f1f1;
}

/* Hide dropdown by default */
.auth-dropdown.hidden {
  display: none;
}

