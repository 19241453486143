/* Main page styling for the updates */
.updates-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white; /* Full white background */
    z-index: 1000; /* Ensure it's on top */
    padding: 0;
    overflow: hidden; /* Disable overflow on the main page */
  }
  
  /* Scrollable box for updates */
  .updates-scroll-box {
    max-height: 80vh; /* Adjust the height for a scrollable box */
    max-width: 66vw;
    margin: auto;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  /* Individual update item */
  .update-item {
    padding-bottom: 20px;
    align-content: left;
  }
  
  /* Styling for the header of each update item */
  .update-item h3 {
    text-align: left;
    margin-bottom: 0px;
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .update-item h4 {
    text-align: left;
    margin-top: 0px;
    font-weight: lighter;
  }
  
  /* Styling for the paragraph of each update item */
  .update-item p {
    text-align: left;
    margin: 0;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .update-item ul {
    list-style-type: disc;
    padding-left: 40px;
    text-align: left;
  }
  
  /* Horizontal line between each update item */
  .update-divider {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #000;
  }
  