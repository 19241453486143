/* Overlay background for popup */
.share-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  /* Container for popup */
  .share-popup-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;  /* Similar size to contact form */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Close button in the top right */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Title */
  .share-popup-container h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  /* Share option buttons */
  .share-option-button {
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .share-option-button:hover {
    background-color: #0056b3;
  }
  
  .share-option-button:last-child {
    background-color: #28a745;
  }
  
  .share-option-button:last-child:hover {
    background-color: #218838;
  }
  