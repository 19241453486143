/* General page styles */
.tutorial-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white; /* Full white background */
    z-index: 1000; /* Ensure it's on top */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    padding: 0;
    overflow: hidden; /* Disable overflow on the main page */
  }
  
  /* Scrollable content inside the tutorial */
  .tutorial-content-container {
    width: 67%; /* 67% width of the screen */
    height: 90%; /* Set a fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .tutorial-content {
    margin-top: 60px; /* Space for the back button */
  }
  
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .step-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .section-divider {
    margin: 40px 0;
    border: 0;
    border-top: 2px solid #ccc;
  }
  
  h1, h2 {
    text-align: center; /* Center h1 and h2 headers */
    align-self: center;
    margin-top: 20px;
  }
  
  .image-spacing {
    margin-right: 20px;
  }
  