.contact-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .contact-form-container {
    background: #f9f9fc;
    padding: 30px;
    border-radius: 15px;
    width: 450px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  .success-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #d4edda; /* Light green background */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb; /* Green border */
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
  }
  h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    text-align: center;
    margin-bottom: 20px;
    color: #555;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .name-inputs {
    display: flex;
    gap: 10px;
  }
  
  .name-inputs input {
    flex: 1;
  }
  
  .contact-form-container label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .contact-form-container input, 
.contact-form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form-container .form-group {
  margin-bottom: 20px;
}

.contact-form-container .submit-button {
  background-color: #000;
  color: #fff;
  padding: 12px 0;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.contact-form-container .submit-button:hover {
  background-color: #333;
}