/* Full container for the reports page */
.reports-background-blur {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: calc(100vh);
  display: flex;
  flex-direction: column; /* Arrange title above content */
  z-index: 10;
  backdrop-filter: blur(0px); /* Blur background */
  overflow: hidden; /* Prevent overflow of the container */
}

.reports-container {
  position: absolute;
  top: 32px;
  left: 0;
  width: 99%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column; /* Arrange title above content */
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6); /* Transparent white */
  backdrop-filter: blur(3px); /* Blur background */
  overflow: hidden; /* Prevent overflow of the container */
  border: solid black;
  border-radius: 10px;
  margin-left: .25%;
  margin-top: .5%
}

.reports-title {
  width: 100%;
  text-align: center;
  background-color: #9d7715; /* Yellow-brown background */
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 0; /* Remove default margins */
  line-height: 1; /* Adjust line-height to make it more compact */
  border-bottom: solid black;
}

.reports-title h1 {
  margin: 0; /* Remove margins around the <h1> tag */
  padding: 5px 0; /* Add minimal padding if necessary */
}
/* Content Area Below Title */
.reports-content {
  display: flex;
  flex-grow: 1;
  overflow: hidden; /* Prevent side panel from resizing */
}

/* Side Panel Styling */
.report-side-panel {
  width: 250px;
  background-color: #006b45; /* Green with slight transparency */
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  border-right: 2px solid #ddd;
  flex-shrink: 0; /* Prevent shrinking */
  border-right: solid black;
}

/* Buttons in the side panel */
.action-button {
  padding: 10px 15px;
  background-color: #fff;
  color: #4caf50;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.action-button:hover {
  background-color: #f1f1f1;
  color: #4caf50;
}

/* Main Panel Styling */
.main-panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow */
  background-color: rgba(255, 0, 0, 0);
}

.table-container {
  flex-grow: 1; /* Allow the table to grow and take available space */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: rgba(255, 255, 255, 0.6); /* Transparent white with slight opacity */
  backdrop-filter: blur(10px); /* Apply a blur effect to the background */
  border: 1px solid #ddd;
  border-radius: 5px; /* Optional: Rounded corners */
}

/* Table Styling */
.report-table {
  width: max-content; /* Table takes only as much space as needed */
  border-collapse: collapse;
}
.report-table th,
.report-table td {
  border: 1px solid black; /* Grid lines between cells */
  padding: 10px;
  text-align: left;
}
.report-table th {
  background-color: rgb(114, 114, 114); /* Dark header background */
  color: #fff; /* White text for headers */
  padding: 10px;
  text-align: left;
}

.report-table td {
  padding: 10px;
  text-align: left;
}

/* Alternating Row Colors */
.report-table tr:nth-child(odd) td {
  background-color: #ebebeb; /* Light gray for odd rows */
}

.report-table tr:nth-child(even) td {
  background-color: #fff; /* White for even rows */
}

/* Highlight for Hovered Rows */
.report-table tr:hover td {
  background-color: #c2c2c2; /* Slightly darker gray for hovered rows */
}

/* Pagination Styling */
.pagination {
  flex-shrink: 0; /* Keep the pagination at the bottom */
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  cursor: pointer;
  border-radius: 3px;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
/* ATTRIBUTE DROPDOWN CONTAINER */
.attribute-dropdown-reports {
  margin-top: 1rem;
  font-family: 'Arial', sans-serif;
}

/* GROUPING FOR EACH DROPDOWN SECTION */
.dropdown-group-reports {
  margin-bottom: 0.75rem;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* HEADER STYLING */
.dropdown-header-reports {
  cursor: pointer;
  background: #9d7715; /* Green background */
  color: white;
  border: none;
  padding: 10px 15px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  transition: background 0.2s ease, transform 0.1s ease;
}

.dropdown-header-reports:hover {
  background: #8a6812; /* Darker green on hover */
}

/* DROPDOWN MENU CONTAINER */
.dropdown-menu-reports {
  background: white;
  color: black;
  border: 1px solid #ddd;
  margin-top: 0.25rem;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* DROPDOWN ITEMS */
.dropdown-item-reports {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
}

/* HOVER EFFECT ON ITEMS */
.dropdown-item-reports:hover {
  background: #e6f7e6; /* Light green highlight on hover */
}

/* CHECKBOXES */
.dropdown-item-reports input {
  margin-right: 8px;
  transform: scale(1.2);
  accent-color: #006b45; /* Green checkboxes */
}




.geo-filter-notice {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.geo-filter-notice span {
  font-weight: bold;
}

.geo-filter-clear {
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.geo-filter-clear:hover {
  background-color: #ff4d4d;
}

.geo-filter-clear:focus {
  outline: none;
}


.header-cell {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.25rem;
}

.header-cell span {
  cursor: pointer;
  font-weight: bold;
}

.header-cell span:hover {
  color: #007bff;
}

.header-cell input {
  width: 100%;
  font-size: 0.9rem;
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

