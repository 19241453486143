/* Fullscreen Login Overlay */
.login-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px); /* More pronounced blur for modern effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Centered login form with glassmorphism effect */
.login-container {
  background: rgba(0, 107, 69, 0.75); /* Semi-transparent green */
  padding: 40px;
  border-radius: 14px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  width: 380px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  border: 2px solid rgba(255, 255, 255, 0.2); /* Light border for contrast */
  backdrop-filter: blur(8px); /* Creates a frosted glass effect */
}

/* Close button - modern circular style */
.close-login {
  position: absolute;
  top: 10px;
  right: 15px;
  background: white;
  border: 2px solid black;
  color: black;
  font-size: 18px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30%; /* Fully circular */
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.2s;
}

/* Hover effect: Red background with white icon */
.close-login:hover {
  background: #cc0000;
  color: white;
  transform: scale(1.1);
}

/* Header container for title & logo */
.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

/* Logo Styling */
.login-logo {
  width: 350px;
  height: auto;
  margin-bottom: -40px;
}

/* Main title */
.title-main {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

/* Subtitle */
.title-sub {
  font-size: 18px;
  font-weight: normal;
  color: white;
}

/* Error & success messages */
.error-message, .success-message {
  font-size: 14px;
  margin-bottom: 12px;
}
.error-message { color: red; }
.success-message { color: green; }


/* Change placeholder text color */
.login-input::placeholder {
  color: rgb(31, 31, 31); /* Red with slight transparency */
  font-weight: 500;
  text-align: center; /* Keep centered */
}
/* Input fields */
.login-input {
  width: 90%;
  padding: 12px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.2);
  color: rgb(255, 255, 255);
  text-align: center;
}

/* Input focus effect */
.login-input:focus {
  outline: none;
  border-color: #9d7715;
  background: rgba(255, 255, 255, 0.3);
}

/* Primary action button */
.login-btn {
  background: linear-gradient(135deg, #9d7715, #71591c);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px;
  width: 90%;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: solid #006b45;
}

/* Hover effect */
.login-btn:hover {
  background: #71591c;
  border: solid white;
  transform: scale(1.05);
}

/* Secondary button - "Forgot Password" */
.reset-email-btn {
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  width: 45%;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: background 0.3s ease-in-out;
}

/* Reset button hover */
.reset-email-btn:hover {
  background: #b30000;
}

/* Register link */
.register-link {
  display: block;
  margin-top: 15px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

/* Register hover effect */
.register-link:hover {
  color: rgb(164, 155, 155);
}

/* Links (Forgot Password / Sign Up) */
.login-link {
  margin-top: 10px;
  font-size: 14px;
}

.login-link small {
  cursor: pointer;
  color: white;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

/* Hover effect for login links */
.login-link small:hover {
  color: rgb(200, 200, 200);
}
