/* src/components/Search.css */
.search-container {
  
  position: absolute;
  top: 32px; /* Adjust based on header */
  left: 0;
  width: 99%;
  display: flex;

  height: calc(100vh - 50px);
  background: #e0c684; /* Opaque white background */
  z-index: 10; /* High z-index to overlay on top of map */
  background-color: rgba(255, 255, 255, 0.6); /* Whitish-gray background with some transparency */
  backdrop-filter: blur(3px);
  border: solid black;
  border-radius: 10px;
  margin-left: .25%;
  margin-top: .5%;
  flex-direction: column; /* Ensure children are stacked vertically */
}
  
.search-tabs {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.search-tabs button {
  flex: 1;
  padding: 12px;
  margin: 0 0px;
  background-color: #f1f1f1;
  border: 2px solid black;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px; /* Larger font size */
}

.search-tabs button.active {
  background-color: #1d784f;
  color: white;
}

.search-content {
  align-items: center;
  width: 100%;
  max-height: 100%; /* Take up full height of parent container */
}

/* Container for Search Bar and Buttons */
.search-bar-and-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align everything */
  margin-bottom: 0px; /* Add space below the container */
  border-bottom: solid black;
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding:5px;

  background-color: #006b45;
  z-index: 10;
  gap:5px
}

.search-bar {
  display: flex;
  justify-content: center; /* Center-align the search bar */
  gap: 10px; /* Add spacing between input and button */
  margin-bottom: 10px; /* Space between search bar and action buttons */
  width: 40%;
}
.search-bar-button-container {
  padding: 20px;
  gap: 20px;
  display: flex; /* Ensures the input and button align horizontally */
  border-bottom: solid black;
  background-color: #006b45;
  gap: 10px; /* Adjust the spacing between the elements */
  align-items: center; /* Vertically align input and button */
  justify-content: center; /* Center the container */
}

/* Styling for the search input */
.search-content input {
  margin: 0; /* Remove left margin */
  width: 50%; /* Keep the width of the input centered */
  padding: 12px 20px;
  border: 2px solid #1d784f;
  border-radius: 30px;
  border: solid black 2px;
  font-size: 18px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-content input:focus {
  border-color: #007bff;
}

/* Styling for the search button */
.search-content button {
  margin-left: 10px; /* Remove top and left margin */
  margin-right: 10px;
  padding: 12px 20px; /* Adjust padding for better sizing */
  background-color: #9d7715;
  
  color: white;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border: solid black 2px;

}

.search-content button:hover {
  background-color: #006b45;
}

.search-results-container {
  
  flex-grow: 1; /* Allow the container to grow within available space */
  max-height: calc(79vh); /* Dynamically calculate height */
  overflow-y: auto; /* Enable vertical scrolling */
  
  margin-left: auto;
  margin-right: auto;
  padding: 10px; /* Inner padding for better layout */
  border-radius: 8px; /* Optional: rounded corners */
  background-color: #006b4600; /* Optional: background color */
  width: 60%;
  
}

/* Optional scrollbar styling for a polished look */
.search-results-container::-webkit-scrollbar {
  width: 8px;
}

.search-results-container::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Custom scrollbar color */
  border-radius: 4px;
}

.search-results-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Top blur effect */
.blur-effect-top,
.blur-effect-bottom {
  position: sticky;
  height: 30px; /* Adjust the height of the blur effect */
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6); /* Whitish-gray background with some transparency */
  backdrop-filter: blur(3px); /* Apply blur effect */
}

/* Top blur sticks to the top */
.blur-effect-top {
  top: 0;
}

/* Bottom blur sticks to the bottom */
.blur-effect-bottom {
  bottom: 0;
}



.search-results-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  width: 100%;
}

.search-result-item {
  padding: 15px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px; /* Rounded corners for modern look */
  border: solid black;
  height: 100px;

}
/* Ensure consistent checkbox size and alignment */
.result-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 5%;
}

/* Increase checkbox size */
.result-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 2px solid #1d784f; /* Green border */
  border-radius: 4px; /* Rounded checkbox */
  background-color: white; /* Default background */
  transition: background-color 0.2s ease, border-color 0.2s ease;
}





.search-result-item.even {
  background-color: #f9f9f9; /* Light grey for even items */
}

.search-result-item.odd {
  background-color: #cbcbcb; /* Slightly darker grey for odd items */
}

.search-result-item hr {

  border: 0;
  border-top: 1px solid #ddd;
}

.result-content {
  width: 100%;
  display: flex;
  align-items: center; /* Vertically aligns elements */
  gap: 20px; /* Add space between checkbox and text */
}

.result-buttons-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  row-gap: 0; /* Remove any gap between rows */
  column-gap: 5px; /* Space between buttons horizontally */
  width: 100px;  /* Adjust width so it doesn't overflow */
  height: 120px; /* Adjust height based on your button size */
  align-items: right; /* Ensure buttons stretch to fill available space */
  justify-items: right; /* Center the buttons horizontally */
  margin-top: 2%; /* Move grid upwards */
  margin-right: 100px; /* Move grid to the left */
  justify-self: flex-end; /* If the parent is a grid */
}

.result-buttons {
  display: flex;
  justify-content: flex-end; /* Aligns buttons to the right */
  flex-grow: 1; /* Allows it to take up remaining space */
}

.result-buttons-grid a, .result-buttons-grid button {
  border: solid black;
  font-size: 14px;
  width: 100%; /* Make buttons fill the grid cell */
  height: 75%; /* Ensure the buttons fully fill the grid rows */
  text-align: center;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid #7a7a7a; /* Add a subtle border */}

  .result-buttons-grid a:hover, .result-buttons-grid button:hover {
    background-color: #0056b3;
    border-color: #0056b3; /* Change border color on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Make shadow larger on hover */
  }

.result-buttons-grid .tax-button {
  background-color: #28a745;
}

.result-buttons-grid .tax-button:hover {
  background-color: #218838;
}

.result-buttons-grid .clerk-button {
  background-color: #6f42c1;
}

.result-buttons-grid .clerk-button:hover {
  background-color: #593196;
}

.result-buttons-grid .map-button {
  background-color: #17a2b8;
}

.result-buttons-grid .map-button:hover {
  background-color: #138496;
}

.result-buttons-grid .detail-button {
  background-color: #ffc107;
}

.result-buttons-grid .detail-button:hover {
  background-color: #e0a800;
}


.no-results {
  text-align: center;
  font-size: 24px;
  color: black;
  margin-top: 20px;
}