/* src/components/SidePanel.css */
.side-panel {
  position: absolute;
  right: 0;
  border: solid black 1px;
  top: 60px; /* Adjust this value based on the height of your ToolPanel */
  height: calc(100vh - 150px); /* Subtract the height of the ToolPanel */
  width: 300px;
  background-color: rgba(255, 255, 255, 0.6); /* Whitish-gray background with some transparency */
  backdrop-filter: blur(5px); /* Apply a blur effect to the background */
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  border-radius: 10px 0 0 10px; /* Rounded top-left and bottom-left corners */
}

.layers-tab{
  margin-left: 7px;
}

.side-panel.closed {
  transform: translateX(100%);
}


.legend {
  list-style: none;
  padding: 0;
  margin: 0;
}
.legend-container {
  margin-top: 5px;
  margin-left: 20px;


}

/* Style the container */
.report-builder-container {
  display: flex;
  justify-content: center; /* Centers the button */
  margin: auto;
  margin-top: 15px;
  margin-right: 20px;
  margin-left: 20px;

   /* Adds spacing above */
}

/* Style the button */
.report-builder-button {
  background-color: #006b45; /* Match green theme */
  color: white;
  padding: 2px 10px;
  font-size: 14px;
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect */
.report-builder-button:hover {
  background-color: #9d7715; /* Darker green on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a hover effect */
}

/* Optional: If you want the button to expand on hover */
.report-builder-button:active {
  transform: scale(0.98); /* Slight press effect */
}


.legend-toggle {
  background-color: transparent;
  border: none;
  color: white;
  background-color: #9d7715;
  border: solid black .5px;
  cursor: pointer;
  padding: 3px;
  font-size: 14px;
  text-align: left;
}
.legend-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.legend-color {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  display: inline-block;
}

.side-panel .toggle-btn {
  position: absolute;
  left: -20px;
  top: 50%;
  width: 20px;
  height: 70px;
  background-color: #1d784f;
  color: white;
  border: none;
  border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
  border-top-left-radius: 10px;
  cursor: pointer;
  z-index: 1003;
}

.side-panel .content {
  padding: 20px;
}

.side-panel .tab-buttons {
  display: flex;
  color: #9d7715;
  justify-content: space-between;
  margin-bottom: 0px;
  
  
}

.side-panel .tab-buttons button {
  flex: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  background-color: #d5d5d5;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #1d784f 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.side-panel .tab-buttons button.active {
  background-color: #9d7715;
  color: white;
  border: solid #1d784f 2px;
  border-bottom: none;

}

.side-panel .tab-content {
  overflow-y: auto;
  border: solid #1d784f 2px;
  border-top: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /*background-color: rgba(255, 255, 255, 0.6); /* Whitish-gray background with some transparency */
  /*backdrop-filter: blur(1px); /* Apply a blur effect to the background */

  height: calc(100vh - 230px);
}

.side-panel h2 {
  text-align: center;
  margin-top: 0;
}

.side-panel ul {
  list-style-type: none;
  padding-left: 2px;
}

.side-panel ul li {
  margin-bottom: 10px;
}

.symbology-container {
  margin-left: 20px;
}

.symbology-list {
  list-style-type: none;
  padding-left: 0;
}

.symbology-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #000;
}

