/* src/pages/AdvancedSearch.css */
.advanced-search-container {
  position: absolute;
  top: 40px; /* Adjust based on header */
  left: 0;
  width: 100%;
  height: calc(100vh -60px);
  background: #e0c684; /* Opaque white background */
  z-index: 10; /* High z-index to overlay on top of map */
}

.search-section {
  margin-bottom: 20px;
}

.search-section h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.search-section input {
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}

.search-section button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}
